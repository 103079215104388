import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { CurveCarveTop, CurveCarveBottom } from "../components/Curves/Curve"
import { FaCheckSquare } from "react-icons/fa"
import { Link } from "gatsby"

const FormSuccess = () => {
  typeof window !== "undefined" &&
    window.gtag &&
    window.gtag("event", "conversion", {
      send_to: "AW-1046599570/KkH3CMOxl80CEJKvh_MD",
    })

  return (
    <Layout>
      <SEO title="Thank you - submission received" />

      <Container className="text-center">
        <Row>
          <Col>
            <h1>Thank you!</h1>
          </Col>
        </Row>
      </Container>

      <CurveCarveTop variant="light" />
      <div className="bg-light  pt-5">
        <Container className="text-center">
          <Row>
            <Col>
              <p>Your form submission has been received.</p>
              <div className="my-5">
                <FaCheckSquare size="100px" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="light" />

      <div className="text-center ">
        <Button as={Link} size="lg" variant="secondary" to="/">
          Return Home
        </Button>
      </div>
    </Layout>
  )
}

export default FormSuccess
